import React from 'react';
import styled from 'styled-components';
import { Typography, Box, Grid, Image } from '.';
import bannerBg from '../images/services/bg.png';

const ServicesHeroSection = styled.section`
  height: 320px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 90%;
  background-image: url(${bannerBg});
  margin-top: 50px;
  @media (max-width: 768px) {
    background-size: 100%;
    height: auto;
  }
`;

const ResponsiveTypography = styled(Typography)`
  padding: 125px 0;
  text-align: center;

  @media (max-width: 786px) {
    padding: 50px 0;
  }
`;

const ServiceHeroSection = ({ title, imageSrc, imgAlt }) => {
  return (
    <>
      <ServicesHeroSection>
        <Grid gridTemplateColumns={{ _: '1fr', sm: '5fr 3fr' }}>
          <Box>
            <ResponsiveTypography variant="heading1">
              {title}
            </ResponsiveTypography>
          </Box>
          <Box display={{ _: 'none', sm: 'block' }}>
            <Image
              style={{ float: 'right', margin: '50px', marginRight: '0px' }}
              src={imageSrc}
              alt={imgAlt}
            />
          </Box>
        </Grid>
      </ServicesHeroSection>
    </>
  );
};

export default ServiceHeroSection;
