import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Box,
  Container,
  Section,
  Layout,
  Grid,
} from '../components';
import styled from 'styled-components';
import ServiceHeroSection from '../components/ServiceHeroSection';
import bannerImage from '../images/services/managedItServices/managedItServicesBanner.svg';
import applicationSupport from '../images/services/managedItServices/applicationSupport.svg';
import itHelpDesk from '../images/services/managedItServices/ItHelpDesk.svg';
import backupandRecovery from '../images/services/managedItServices/backupandRecovery.svg';
import deviceManagement from '../images/services/managedItServices/deviceManagement.svg';

const ServiceContentImage = styled.img`
  box-shadow: 25px 25px 0px 0px var(--secondary-color);
  margin: 25px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    box-shadow: 10px 10px 0px 0px var(--secondary-color);
  }
`;

const managedItServices = () => {
  return (
    <Layout title="Managed IT Services">
      <Helmet>
        <meta
          name="description"
          content="Reliable IT infrastructure management by certified experts. Proactive security, seamless support, backup solutions, and device management for optimized functionality and data protection."
        />
      </Helmet>
      <Container mt={{ xs: 8, md: 12 }}>
        <ServiceHeroSection
          title={'Managed IT Services'}
          imageSrc={bannerImage}
          imgAlt={'Managed IT Services'}
        />
        <Section>
          <Typography my={5} variant="paragraph1">
            Get our qualified and certified team of experts to handle your IT
            infrastructure. We proactively ensure maximum security, hassle-free
            troubleshooting, optimum functionality, highest server uptime, and
            reliable backups.
          </Typography>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={applicationSupport} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Application Support
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We monitor your applications for performance issues,
                troubleshoot problems, and apply updates to keep them
                functioning smoothly.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                IT Help Desk
              </Typography>

              <Typography mt={2} variant="paragraph1">
                Our technicians are highly responsive to solve queries,
                troubleshoot problems, and resolve technical issues promptly and
                efficiently.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage
                style={{ float: 'right' }}
                src={itHelpDesk}
              />
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={backupandRecovery} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Backup and Recovery
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We offer robust backup solutions and swift recovery services to
                safeguard your valuable information against any unforeseen
                events.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Device Management
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We take care of device provisioning, configuration, security
                patching, and monitoring so all your devices are secure,
                compliant, and functioning optimally.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage
                style={{ float: 'right' }}
                src={deviceManagement}
              />
            </Box>
          </Grid>
        </Section>
      </Container>
    </Layout>
  );
};

export default managedItServices;
